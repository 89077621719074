.team {
  width: 85%;
}

.team-btn {
  text-align: center;
  margin: 2rem auto 2rem;
  border-bottom: 3px solid var(--color-purple);
  border-top: 3px solid var(--color-purple);
  width: 50%;
  background-color: aliceblue;
}

.team-btn button {
  box-shadow: none;
}
.profile {
  background-color: var(--color-primary);
  margin: 20px 20px;
  padding: 20px 0;
  border: 2px solid transparent;
  transition: var(--transition);
  position: relative;
  overflow: hidden;
  width: 17.3rem;
}

.profile:hover {
  border: 2px solid var(--color-primary);
  background-color: white;
}

.profile img {
  height: 18rem;
  width: 15rem;
  object-fit: cover;
}

.profile:hover img {
}

.profile h3 {
  font-weight: 600;
  margin: 5px 0 3px 0;
  font-size: 20px;
}

.profile p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: -0.5rem;
}
.t-con {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.t-con p {
  text-align: center;
}
.team-socials {
  background-color: var(--color-purple);
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0.8rem;
  border-radius: 15px 0 0 15px;
  top: 30%;
  right: -100rem;
  box-shadow: -2rem 0 2rem rgba(0, 0, 0, 0.3);
  transition: 0.5s;
}

.profile:hover .team-socials {
  right: -1px;
}

.team-socials i {
  padding: 8px 0;
  color: var(--color-primary);
  font-size: 23px;
}

.active-btn {
  color: blueviolet;
  padding: 100px 10px;
  background-color: var(--color-primary);
}

@media screen and (max-width: 1355px) {
  .team-btn button {
    margin: 15px 15px 10px 0;
  }
}
@media screen and (max-width: 1300px) {
  .t-con {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 780px) {
  section .team {
    margin-top: 45vh;
  }

  .t-con {
    grid-template-columns: repeat(2, 1fr);
  }

  .team-btn {
    margin-top: 2rem;
    width: 100%;
  }

  .team-btn button {
    margin: 10px 10px 10px 0;
  }
}

@media screen and (max-width: 480px) {
  .t-con {
    grid-template-columns: repeat(1, 1fr);
  }
}
