.head {
  background-color: var(--color-primary);
  /* height:fit-content; */
  width: 1100px;
  top: 0;
  color: var(--color-white);
  border-radius: 0 0 10px 0;
  padding: 1px 15px;
}

.head-left,
.head-right {
  padding: 1px 20px;
}

.head-left h3 {
  margin-right: 25px;
  font-weight: 600;
}

.head-right i {
  font-size: 25px;
  margin-top: 3px;
  color: white;
}

.head a {
  color: inherit;
  font-weight: 600;
}
.header {
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  z-index: 99999;
  transition: height 1s;
  height: 14vh;
  margin-top: 10px;
  overflow: hidden;
}
.header {
  position: sticky;
  top: 0;
}

.header ul {
  display: flex;
  align-items: center;
  text-align: center;
}

.header ul li {
  margin: auto 40px auto 0px;
}

.active {
  color: var(--color-primary) !important;
}

.logo {
  max-width: 35rem;
  height: 14vh;
  clip-path: polygon(0 0, 95% 0, 75% 100%, 0% 100%);
  background-color: blueviolet;
  transition: height 1s;
  align-items: center;
}

.logo-new {
  height: 12vh;
}
.logo img {
  height: 6rem;
  width: 6rem;
  margin-left: 5rem;
  margin-top: 1.2rem;
}

.logo-title {
  line-height: 30px;
  /* margin-top:4px; */
  font-size: 15px;
  margin: auto 5rem auto 0;
}

.logo-title h2 {
  color: var(--color-white);
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 3px;
}

.logo-title h5 {
  color: white;
  font-weight: 500;
}

.nav ul {
  height: 14vh;
  margin: auto;
  /* margin-right: -10rem; */
}
#navBar li::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 4px;
  /* background: var(--color-primary); */
  background: blueviolet;
  top: 60px;
  transition: all 0.45s;
}

#navBar li:hover::before {
  width: 47px;
}

#navBar li:nth-child(1):hover::before {
  width: 51px;
}

#navBar li:nth-child(2):hover::before {
  width: 78px;
}

#navBar li:nth-child(3):hover::before {
  width: 82px;
}
#navBar li:nth-child(4):hover::before {
  width: 131px;
}
#navBar li:nth-child(5):hover::before {
  width: 58px;
}
#navBar li:nth-child(6):hover::before {
  width: 92px;
}

.window-scroll {
  background-color: var(--color-primary);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  height: 12vh;
}
#mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}
#mobile i {
  display: none;
}

#mobile i {
  color: blueviolet;
  font-size: 22px;
  margin: 0 80px 0px 0;
}

li a {
  color: var(--color-white);
}

li a:hover {
  color: blueviolet;
}

.active-link {
  color: blueviolet;
}

/* ============(MQ) Responisve========== */
@media screen and (max-width: 1230px) {
  #mobile i {
    display: block;
  }

  #navBar {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 140px;
    right: -400px;
    padding: 40px 0 0 10px;
    /* background-color: purple; */
    width: 300px;
    height: 50vh;
    transition: 0.3s ease-in-out;
  }

  #navBar.active {
    right: 0;
  }
  #navBar li {
    background-color: rgba(0, 190, 250, 0.8);
    width: 100%;
    padding: 0.8rem;
  }

  #navBar li:hover::before {
    content: "";
    display: none;
  }
}

/* ===========(MQ)Tablet============= */
@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden !important;
  }

  .head {
    width: 768px !important;
    border-radius: 0;
    padding: 0;
    /* max-width:768px ! important; */
    /* overflow-x:hidden !important ; */
  }
  .head-left {
    margin: auto;
  }
  .head h3 {
    font-weight: 400;
    font-size: small;
  }

  .head-right {
    display: none;
  }
  .header ul {
    margin-top: 2rem;
  }
  .header ul li {
    margin: 0;
  }
  .header {
    width: 100%;
    height: 12vh;
  }

  #navBar {
    top: 108px;
  }

  #navBar li {
    margin-right: 2rem;
  }

  .logo {
    height: 12vh;
  }

  .logo img {
    margin-left: 2rem;
    margin-top: 2.5rem;
  }

  .logo-title {
    margin-top: 1.2rem;
  }
  .logo-title h2 {
    font-size: 17px;
    font-weight: 600;
  }

  .window-scroll {
    height: 10vh;
  }

  .logo-new {
    height: 10vh;
  }
  #mobile {
    width: 3rem;
    margin-right: 2rem;
  }
}

/* ==========MQ(Mobile)========= */
@media screen and (max-width: 480px) {
  .head {
    display: none;
  }

  #navBar li {
    padding: 0.6rem 0rem;
    font-size: smaller;
    margin-right: 1rem;
  }
  .header ul {
    margin-top: -2rem;
  }
  .header {
    margin-top: 0;
    /* background-color:var(--color-purple) */
  }
  .logo {
    min-width: 20rem;
    clip-path: polygon(0 0, 70% 0%, 35% 100%, 0% 100%);
  }

  .logo img {
    height: 4rem;
    width: 4rem;
    margin: 1.2rem 2rem auto 40px;
  }
  .logo {
    background-color: var(--color-purple);
    width: 2rem;
  }
  .logo-title {
    display: none;
  }
  #mobile {
    width: 2.5rem;
  }

  #mobile i {
    margin: 0 20px 0 0;
  }
}
