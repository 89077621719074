.carousel-img img {
  object-fit: cover;
  width: 100.1%;
  height: 27rem;
}

.parent {
  position: relative;
}

.middle-design {
  background: url(../../../../public/images/carousel-img/second\ copy.png);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  min-width: 37rem;
  left: 51%;
  transform: translateX(-50%);
  min-height: 37rem;
  top: -3rem;
  z-index: 9999;
  background-size: contain;
}

.mdl-text-sec {
  margin: 17rem 2.4rem 0 0;
  text-align: center;
}

.middle-design h1 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.8rem;
  color: white;
}

.middle-design h3 {
  margin-top: 3rem;
  color: white;
  font-weight: 500;
}

.swiper-button-next,
.swiper-button-prev {
  color: blueviolet;
}

@media screen and (max-width: 768px) {
  .middle-design {
    position: relative;
  }

  .mdl-text-sec {
    margin-top: 1.8rem;
    padding-top: 17rem;
  }
}

@media screen and (max-width: 480px) {
  .middle-design {
    min-width: 24rem;
  }

  .middle-design h1 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 2rem;
  }

  .middle-design h3 {
    font-size: 13px;
    font-weight: 400;
  }

  .mdl-text-sec {
    margin: -8rem 2.4rem 0 0;
    text-align: center;
  }
}
