.img img {
  object-fit: cover;
  height: 58vh;
  width: 50vh;
  border-radius: 15px;
  box-shadow: -20px 30px 10px -11px rgba(0 0 0/40%);
  margin-right: 3rem;
}

.school-description {
  width: 30rem;
  min-width: 15rem;
  margin-right: 25rem;
}

.school-description p {
  color: var(--color-text);
  font-weight: 400;
  line-height: 2rem;
}

.school-description h2,
.principal-word h2 {
  color: var(--color-primary);
  font-size: 33px;
}

.school-description button,
.principal-section button {
  margin-top: 17px;
  padding: 10px 23px;
}

.principal-section {
  display: flex;
  flex-direction: row-reverse;
}

.principal-img img {
  object-fit: cover;
  height: 85vh;
  width: 85vh;
  border-radius: 15px;
  /* box-shadow:-20px 30px 10px -11px rgba(0 0 0/40%); */
  margin-left: 45rem;
}

.principal-word {
  max-width: 25rem;
  z-index: 2;
  margin-right: -55rem;
  margin-top: 5vh;
  /* height:75vh; */
  height: fit-content;
  background-color: #f5f5f5;
  border-radius: 15px;
  padding: 10vh 5vh;
  box-shadow: -20px 30px 10px -11px rgba(0 0 0/40%);
}

.principal-word h3 {
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 15px;
  font-weight: 500;
}

.principal-word h4 {
  font-weight: 600;
  font-size: 0.9rem;
  margin: 25px 0;
}

.principal-word p {
  line-height: 2rem;
}
/* ==========MQ(Tablets)============= */
@media screen and (max-width: 768px) {
  .aboutUs {
    /* padding-top:2rem; */
  }
  .img img {
    height: 38vh;
    width: 30vh;
    margin-right: 1rem;
  }
  .intro {
    /* margin-top:20rem; */
    /* margin-top:80vh; */
  }

  .school-description {
    width: 15rem;
    margin-right: 8rem;
  }

  .principal-img img {
    object-fit: cover;
    height: 35vh;
    width: 35vh;
  }
  .principal-word {
    width: 22rem;
    margin-right: -50rem;
    margin-top: 5vh;
    height: fit-content;
    padding: 3vh 1.5vh;
  }
  .principal-word h4 {
    margin: 5px 0;
  }

  .principal-word p {
    line-height: 1.8rem;
  }
  .principal-section h2,
  .about-school h2 {
    font-size: 20px;
  }

  .principal-section h3 {
    font-size: 14px;
  }
}

/* ==========MQ(Phones)============= */
@media screen and (max-width: 480px) {
  .principal-section,
  .about-school {
    display: grid;
  }

  .intro {
    margin-top: -4rem;
  }

  .img {
    margin: auto;
    text-align: center;
  }
  .img img {
    height: 30vh;
    width: 43.3vh;
  }

  .school-description {
    width: 100%;
    padding: 2rem 1rem;
  }
  .school-description p,
  .principal-word p {
    line-height: 1.5rem;
  }

  .principal-img {
    margin: auto;
  }
  .principal-img img {
    height: 30vh;
    width: 43.3vh;
    margin: auto;
  }
  .principal-word {
    width: 100%;
    padding: 2rem 1rem;
    margin-top: 5vh;
    /* height:35vh; */
    height: fit-content;
  }
}
