.thadar-img {
  background-image: url("../../../../public/images/home.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-attachment: fixed;
}

.thadar {
  margin-bottom: -3rem;
}

.thadar-text {
  width: 40rem;
  margin-top: 10rem;
  text-align: justify;
}

.thadar-text h2 {
  color: var(--color-white);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  -webkit-text-stroke: 0.5px gray;
}

.thadar-text h1 {
  font-size: 45px;
  color: var(--color-white);
  margin: 7 0;
  background: -webkit-linear-gradient(#00befa, blueviolet, #00befa, #00befa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* -webkit-text-stroke: 1px gray; */
}

.thadar-text p {
  line-height: 30px;
  font-size: 18px;
  /* color:var(--color-text2); */
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 1.5px;
  word-spacing: 4px;
}

.thadar-btn button {
  padding: 12px 17px 12px 20px;
}

.thadar-btn .btn-secondary:hover {
  color: white;
}
@media screen and (max-width: 768px) {
  .container {
    width: var(--container-width-md);
  }

  .thadar-img {
    width: 48rem;
    height: 80vh;
    background-size: cover;
  }

  .thadar-text {
    margin-top: 2rem;
    width: 30rem;
  }

  .thadar-text h2 {
    letter-spacing: normal;
    font-weight: 500;
    font-size: 20px;
  }

  .thadar-text h1 {
    font-size: 35px;
    margin: 0;
  }

  .thadar-text p {
    font-size: 16px;
  }

  .thadar {
    margin-bottom: 6rem;
    padding-bottom: 5rem;
  }
}

/* =========MQ(Mobile)============= */
@media screen and (max-width: 480px) {
  .thadar {
    padding-bottom: 1rem;
  }
  .container {
    width: var(--container-width-sm);
  }

  .thadar-img {
    background-image: url("../../../../public/images/schoolhome.jpg");
    max-width: 100%;
    height: 70vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .thadar-text {
    width: 20rem;
    margin: 4rem auto 20px;
    /* background-color: red; */
  }

  .thadar-text h1 {
    margin: 0;
    font-size: 23px;
  }

  .thadar-text h2 {
    font-size: 15px;
  }

  .thadar-text p {
    text-align: left;
    line-height: 1rem;
    font-size: 11px;
    word-spacing: 1px;
    margin-top: 10px;
  }
  .thadar-btn {
    margin-top: 5.5rem;
  }
}
