.counter{
    background-color: var(--color-primary);
    display: flex;
    justify-content: space-evenly;
    padding:4rem;
    box-shadow:-20px 0px 80px -11px var(--color-primary);
    transition: var(--transition);
    position: relative;
    overflow: hidden;
}

.counter::after{
    content: "";
    position: absolute;
    width:0;
    height: 0;
    left:0;
    top:-5rem;
    border:5rem solid blueviolet;
   border-color:transparent;
   border-left-color:blueviolet;
}

.counter:hover{
    box-shadow: none;
   }
   
.counter-com {
    align-items: center;
    justify-content: center;
    color:#5A14A5;
   width:15rem;
    display: flex;
    flex-direction: column;
   
}
.counter-com{
    min-width:10rem;
    /* border-left:2.5px solid #5A14A5; */
    height:7.5rem;
}

.counter :nth-child(1){
    border-left: none;
}

.counter-com i{
font-size: 20px;
}

.counter-com h1{
    font-size: 65px;
    margin:-1rem auto -1.5rem;
    
}


@media screen and (max-width:1200px) {
    .counter{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .counter :nth-child(2), .counter :nth-child(3), .counter :nth-child(4){
        border-left: none;
    }
    .counter-com{
        border-bottom:2.5px solid #5A14A5;
    }
    .counter :nth-child(3), .counter :nth-child(4){
        border-bottom:none;
    }
    .counter :nth-child(1), .counter :nth-child(2){
        padding-bottom:1rem;
    }
    .counter-com{
        width:100%;
    }
    .counter-com h1{
        font-size: 55px;
        margin:-2rem auto -2rem;
    }
}

@media screen and (max-width:480px) {
    .counter{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .counter :nth-child(1), .counter :nth-child(2){
        border-bottom:none;
    }
    .counter{
        background-color: transparent;
        box-shadow:none;
        
    }
    .counter-com{
        width:85%;
       margin-bottom: 10px;
        background-color: var(--color-primary);
        height: 8rem;
    }
    .counter-com h1{
        font-size: 30px;
       
    }
    .counter :nth-child(3) h4{
        line-height: 15px;
        margin:20px 0 1rem ;
    }
    .counter h4{
        margin-top:20px;

    }
}