.contact{
    display: flex;
    justify-content: space-between;
    gap:7rem;
    
}
.contact-mapSec{
    padding:2rem 1rem;
    width:50%;
    border-top:2px solid var(--color-primary);
    border-bottom:2px solid var(--color-primary);
    border-radius:50px;
    background-color: aliceblue;
}
.mapSec-list li{
    margin: 1rem 0;
}

.mapSec-list li i{
    margin-right: 20px;
}

.con-sec{
    margin:0.8rem 0;
}

.con-sec i{
    font-size: 30px;
    margin:5px 15px 20px 0;
}
iframe{
    height:15rem;
    width:100%;
}
.contact-form{
    padding:2rem 1rem;
    width:50%;
    border-top:2px solid var(--color-primary);
    border-bottom:2px solid var(--color-primary);
    /* border-radius:50px; */
    background-color: aliceblue;
}
.contact-form input{
    width:100%;
    padding:12px 5px;
    margin-top:10px;
    outline: none;
    /* border:2px solid var(--color-purple) */
    border:2px solid #7c69ef;
}

.contact-form textarea{
    width:100%;
    padding:10px 5px;
    margin-top:10px;
    outline: none;
    border:2px solid #7c69ef;
    /* border:2px solid blueviolet; */
    resize: none;
   
}

.text-area-span{
    float:left;
}
.contact-form p{
    color:red;
    font-size: 13px;
    width:100%;
    height:fit-content;
    word-spacing: 0.1px;
    line-height: 18px;
    text-align: left;
   
}

form .formName{
    width:45%;
    margin-right:5%;
}
.contact-form button{
    float: left;
}
.contact-form button:hover{
    transform: scale(0.9);
}

textarea.input-error,
input.input-error
{
    border-color:#fc8181;
}

.renderAlert{
    padding:10px;
    color:#20c997;
    background-color: rgba(0,190,250,0.2);
    text-align: center;
    margin-bottom:10px;
}

@media screen and (max-width:1200px) {
    .contact{
        gap:2rem;
    }
    .contact-mapSec,
    .contact-form{
        padding:1rem 0.5rem;
        height:36rem;
    }

    .mapSec-list li{
        margin: 0.7rem 0;
    }
}


    
@media screen and (max-width:768px) {

    section .details-contact{
        margin-top:45vh;
     }
    .contact{
        gap:1rem;
        padding-top:5rem;
    }
    .contact-mapSec,
    .contact-form{
        padding:1rem 0.5rem;
        height:30rem;
        
    }

    .mapSec-list li{
        margin: 0.1rem 0;
    }

    .con-soc i{
        font-size: 25px;
        margin:5px 5px 15px 0;
    }
    iframe{
        height:12rem;
        width:100%;
    }
    .contact-form input{
        padding:6px 3px;
        margin-top:10px;
    }
    .contact-form textarea{
        height:8rem;
        padding:6px 3px;
    }
    .contact-form button{
        margin:0.7rem 0;
    }
   
}

    
@media screen and (max-width:480px) {
    .contact{
        display: grid;
        grid-template-columns: 1fr;
    }
    .contact-mapSec,
    .contact-form{
        width:100%;
    }
}