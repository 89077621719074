.main-bg {
  width: 100%;
  height: 100%;
  top: 5rem;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.main-bg i {
  color: white;
  font-size: 28px;
  cursor: pointer;
}

.main-bg .pop-up {
  width: auto;
  max-width: 90%;
  max-height: 90%;
}

.close-btn i {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 28px;
}
@media screen and (max-width: 768px) {
  .main-bg .pop-up {
    max-width: 80%;
    max-height: 80%;
  }

  .close-btn i {
    top: 70px;
  }
}

@media screen and (max-width: 480px) {
  .main-bg .pop-up {
    max-width: 100%;
    max-height: 80%;
  }

  .main-bg i {
    color: white;
    font-size: 25px;
  }
}
