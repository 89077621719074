.about-i {
  max-width: 30rem;
  overflow: hidden;
}

.about-i p {
  color: var(--color-text2);
  line-height: 20px;
}

.prin-imgSec img {
  height: 150px;
  width: 225px;
  object-fit: cover;
  float: left;
  border-radius: 10px;
  margin: 0 20px 10px 0;
}

.prin-imgSec {
  position: relative;
}

.a-f,
.a-s {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 7%;
}

.h-l {
  border-bottom: 1px solid lightgray;
  padding-bottom: 2rem;
  width: 100%;
}

.about-i h1 {
  margin: 20px 0;
  font-size: 25px;
  font-weight: 600;
  color: var(--color-purple);
}

.head-m {
  position: absolute;
  border-right: 5px solid var(--color-primary);
  padding: 2px 5px;
  background-color: rgba(128, 0, 128, 0.3);
  top: 6rem;
  right: 100rem;
  border-radius: 5px 0px 0px 5px;
  transition: 0.5s;
  width: 10rem;
  height: fit-content;
  text-align: right;
}

.head-m h3 {
  font-weight: 600;
  color: white;
  margin-bottom: -8px;
}

.head-m span {
  color: var(--color-primary);
  font-weight: 400;
}

.aboutUs:hover .head-m {
  right: 15.9rem;
}

.objeSec ul li {
  margin-bottom: 10px;
}

.objeSec ul {
  list-style: disc;
  padding-left: 20px;
}

@media screen and (max-width: 1300px) {
  .head-m {
    left: 0;
  }

  .about-i {
    max-width: 23rem;
  }
  .objeSec ul li i {
    font-size: small;
    margin-bottom: 10px;
  }

  .a-f,
  .a-s {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .prin-imgSec img {
    height: 150px;
    width: 250px;
    object-fit: cover;
    float: none;
    display: flex;
    margin: auto;
  }
  section .aboutUs {
    margin-top: 45vh;
  }
  .about-i {
    max-width: 18rem;
  }

  .head-m {
    margin-left: 109px;
  }
  .about-i h1 {
    margin: 8px 0;
    font-size: 22px;
    font-weight: 600;
  }
  .about-i p {
    font-size: small;
  }
  .objeSec ul li i {
    font-size: small;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .a-f,
  .a-s {
    grid-template-columns: 1fr;
  }
  .h-l1 {
    border-bottom: 1px solid lightgray;
    padding-bottom: 2rem;
    width: 100%;
  }
}
