.notices-and-events {
  /* padding-top:13rem; */
}

.notices-events {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  overflow: hidden;
}

.items {
  /* height: 35rem; */
  height: fit-content;
  max-width: 25rem;
  border: 10px solid blueviolet;
  overflow: hidden;
  position: relative;
}

.items i {
  position: absolute;
  top: 45%;
  left: 45%;
  z-index: 3;
  font-size: 50px;
  opacity: 0;
  transition: 0.5s ease-in-out;
  color: white;
}
.n-e-img {
  position: relative;
}

.n-e-img span {
  font-size: small;
  /* position: absolute; */
  display: flex;
  align-items: center;
  margin-left: -0.5rem;
}
.n-e-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  transition: 0.5s;
}

.n-e-img:hover img {
  transform: scale(1.2);
  cursor: pointer;
}

.n-e-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 24rem;
  height: 35rem;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.n-e-img::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 10px;
  width: 21.8rem;
  height: 40rem;
  z-index: 1;
  border-left: 2px dashed var(--color-primary);
  border-right: 2px dashed var(--color-primary);
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.n-e-img:hover::before,
.n-e-img:hover::after,
.n-e-img:hover i {
  opacity: 1;
  cursor: pointer;
}

.n-e-date {
  position: absolute;
  top: 0;
  background-color: var(--color-primary);
  text-align: center;
  padding: 0.5rem;
  z-index: 2;
}

.n-e-desc {
  background-color: rgba(240, 248, 255, 0.8);
  position: absolute;
  bottom: 0;
  height: fit-content;
  padding: 30px 5px;
}

.n-e-desc h2 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 20px;
  margin-bottom: 0.8rem;
}

.n-e-desc p {
  justify-content: left;
  line-height: 23px;
  word-spacing: -3px;
}

.popup {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 4;
}

.hide {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.popup-content {
  position: absolute;
  top: 12%;
  left: 35%;
}

.popup-content i {
  position: absolute;
  color: white;
  z-index: 9;
  left: 50rem;
  top: 0rem;
}
.popup img {
  height: 42rem;
  width: 30rem;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .notices-events {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .notices-and-events .sec-head {
    margin: 1rem 0 2rem 0;
  }
  section .notices-and-events {
    margin-top: 45vh;
  }
  .notices-events {
    gap: 0.7rem;
  }
  .n-e-desc {
    height: 7rem;
    padding: 20px 5px;
  }

  .n-e-desc h2 {
    font-size: 19px;
  }

  .n-e-desc p {
    line-height: 18px;
    font-size: 14px;
  }

  .n-e-img::after {
    width: 18.6rem;
    height: 37.2rem;
  }
  .popup-content {
    left: 20%;
    top: 10%;
  }
  .popup img {
    width: 28rem;
    height: 38rem;
  }

  .popup-content i {
    left: 33rem;
  }
}

@media screen and (max-width: 480px) {
  .notices-events {
    grid-template-columns: repeat(1, 1fr);
  }
  .items {
    margin: auto;
    max-width: 18rem;
  }
  .popup-content {
    left: 10%;
    top: 15.5%;
  }
  .popup img {
    width: 20rem;
    height: 30rem;
  }

  .popup-content i {
    left: 18.5rem;
    top: 30rem;
  }

  .n-e-img i {
    left: 38%;
  }

  .n-e-img::after {
    width: 15.3rem;
    height: 32.7rem;
  }
}
