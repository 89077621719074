.testimonials-head {
  position: relative;
}
.testimonials-head::before {
  content: "";
  position: absolute;
  top: 110%;
  left: 0;
  width: 1rem;
  height: 1rem;
  background-color: var(--color-purple);
}

.testimonials-head::after {
  content: "";
  position: absolute;
  height: 0.2rem;
  min-width: 9rem;
  background-color: var(--color-purple);
  left: 15px;
  top: 126.5%;
}

.test-img img {
  width: 6rem;
  border-radius: 50%;
  height: 6rem;
  border: 5px solid var(--color-purple);
  object-fit: cover;
}

.test-con {
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
}

.test-up {
  max-width: 30rem;
  height: fit-content;
  background-color: aliceblue;
  position: relative;
  padding: 2rem 2.5rem;
}

.test-up p {
  font-size: 13px;
  line-height: 18px;
}

.test-up p::before {
  content: "\f10d";
  position: absolute;
  top: 10px;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 2rem;
  left: 0.6rem;
  color: var(--color-purple);
}

.test-up::before {
  content: "";
  position: absolute;
  border: 2.3rem solid transparent;
  width: 0;
  height: 0;
  border-top-color: aliceblue;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.test-up i {
  font-size: 2rem;
  color: var(--color-purple);
  position: absolute;
  right: 10px;
}
.test-down {
  align-items: center;
  display: flex;
  max-width: 30rem;
  margin: 2rem 0;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.test-con h3 {
  font-size: 20px;
  line-height: 15px;
}

.test-img-desc h4 {
  font-weight: 700;
  margin: 5px 0;
  line-height: 16px;
}

@media screen and (max-width: 768px) {
  .test-up p {
    font-size: 10px;
    line-height: 16px;
  }

  .test-up i {
    font-size: 1.5rem;

    right: 20px;
  }

  .test-up p::before {
    font-size: 1.5rem;
    top: 20px;
  }

  .test-img img {
    max-width: 5rem;
    height: 5rem;
    border: 5px solid var(--color-purple);
  }
}
