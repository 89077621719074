.footer{
    background-image: url("../../../../public/images/foot-3.jpg");
    /* height:20rem; */
    height: 100%;
    width:100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1.5fr;
    gap:2rem;
    padding:5% 10%;
    background-size: cover;
}
.foot-first h1{
color:var(--color-primary);
}
.foot-first span{
    font-weight: 500;
    color:rgb(82, 80, 80);
}
.foot-first p{
    color:rgb(82, 80, 80);
    line-height:20px;
    margin-top:1rem;
    max-width:23rem;
}
.footer h3{
    color: var(--color-primary);
    margin-bottom: 10px;
    font-size: 20px;
}
.copyright{
    text-align: center;
    padding:10px;
    background-color:#61B8E8;
}
.copyright .span-f{
    padding-right:10px;
    border-right:3px solid black;
}

.span-s{
    padding-left:10px;
}

.foot-second li a{
    color:rgb(82, 80, 80);
}

.foot-second li a:hover{
    color:var(--color-primary);
}

.foot-third li{
    color:rgb(82, 80, 80);
}

.footer i{
    color:rgb(82, 80, 80);
}

.foot-third h2{
 font-weight: 500;
 font-size: 15px;
 color:var(--color-primary);

}

@media screen and (max-width:768px){
    .footer{
    gap:0.5rem;
    padding:2% 6%;
    }

    .foot-first p{
        max-width:17rem;
    }
    
    
}

@media screen and (max-width:480px){
.footer{
    grid-template-columns: 1fr;
    height: fit-content;
    gap:2rem;
    padding:2rem 2rem;
}
}