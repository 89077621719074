@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  user-select: none;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  line-height: 1.7;
  width: 100%;
}

:root {
  --color-primary: #00befa;
  --color-secondary: #79dae8;
  --color-3: #192f59;
  --color-white: #fff;
  --color-text: #87879c;
  --color-text2: #6b6b6b;
  --color-purple: #5a14a5;
  --color-footer: #61b8e8;

  --container-width-lg: 80%;
  --container-width-md: 90%;
  --container-width-sm: 94%;

  --transition: 0.7s;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  font-weight: bold;
  /* color:var(--color-white); */
  /* opacity: 0.9; */
  transition: var(--transition) ease-in-out;
}

.container {
  width: var(--container-width-lg);
  margin: auto;
}

.flexSB {
  display: flex;
  justify-content: space-between;
}

h3 {
  font-weight: 500;
  font-size: 17px;
}

i {
  margin: auto;
  padding: 0 10px;
}
p {
  text-align: justify;
}
button {
  border: none;
  background-color: transparent;
}

.container {
  width: var(--container-width-lg);
}

section {
  padding: 6rem 0;
}

button {
  padding: 10px 30px;
  border: 2px solid transparent;
  font-weight: 600;
  margin: 30px 18px 30px 0;
  cursor: pointer;
  box-shadow: 0px 30px 36px -11px rgba(0 0 0/40%);
  transition: var(--transition);
}
.btn-primary {
  color: var(--color-white);
  background-color: var(--color-primary);
}
.btn-primary:hover {
  background-color: var(--color-white);
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  box-shadow: none;
}

.btn-secondary {
  color: var(--color-primary);
  background-color: var(--color-white);
}

.btn-secondary:hover {
  color: var(--color-white);
  background-color: var(--color-primary);
  border: 2px solid var(--color-white);
  box-shadow: none;
}

.back {
  background-image: url("../public/images/schoolfirst.jpg");
  background-position: center;
  height: 80vh;
  width: 100%;
  background-size: cover;
  top: 0;
  position: absolute;
  z-index: -1;
  background-attachment: fixed;
  padding-top: 20rem;
  padding-left: 10%;
}
.back-text {
  background-color: rgba(3, 196, 255, 0.3);
  max-width: fit-content;
  padding: 5px 15px;
  border-bottom: 4px solid var(--color-purple);
}
.back h1 {
  font-size: 50px;
  font-weight: 600;
  color: whitesmoke;
}

.back h3 {
  color: whitesmoke;
  font-weight: 500;
}

.margin-back {
  /* margin-bottom:18.8rem; */
  margin: 15rem 0;
}

section .sec-head {
  width: 100%;
  margin: 2rem 0;
}

.sec-head h1 {
  color: #00befa;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  border-bottom: 4px solid var(--color-purple);
  width: fit-content;
  margin: auto;
}

.scrollUp-btn {
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  color: blueviolet;
  background-color: aliceblue;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  cursor: pointer;
}

.scrollUp-btn i {
  animation: gototop 1.2s linear infinite alternate-reverse;
}

@keyframes gototop {
  0% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateY(0.3rem);
  }
}

/* =======(MQ)Tablets======= */
@media screen and (max-width: 768px) {
  .scrollUp-btn {
    right: 3rem;
    bottom: 4rem;
  }

  section {
    /* padding:1rem 0; */
    /* margin-top:80vh; */
  }
  button {
    padding: 10px 20px;
  }

  h2 {
    font-size: 20px;
  }
  h1 {
    font-size: 22px;
  }
  .margin-back {
    margin: 25rem 0;
  }
  .back {
    height: 80vh;
  }
  .back-text {
    /* margin-top:-5rem; */
  }
  .back h1 {
    font-size: 40px;
  }

  section .sec-head {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* =======(MQ)Phones======= */
@media screen and (max-width: 480px) {
  .scrollUp-btn {
    right: 2rem;
    bottom: 2rem;
    width: 2rem;
    height: 2rem;
  }

  p {
    font-size: 13px;
    line-height: 1.5rem;
  }
  button {
    padding: 8px 8px;
  }

  button {
    font-size: 10px;
  }
  .margin-back {
    margin: 25rem 0;
  }

  .back h1 {
    font-size: 30px;
  }
  section .sec-head {
    margin-top: 0;
    margin-bottom: 0;
  }

  .back {
    height: 70vh;
  }
}
